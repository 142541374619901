@font-face {
    font-family: "Lato";
    src: url("/fonts/Lato-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Lato";
    src: url("/fonts/Lato-Italic.woff2") format("woff2");
    font-style: italic;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Lato";
    src: url("/fonts/Lato-Bold.woff2") format("woff2");
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Lato";
    src: url("/fonts/Lato-BoldItalic.woff2") format("woff2");
    font-style: italic;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Karbon";
    src: url("/fonts/Karbon-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Karbon";
    src: url("/fonts/Karbon-RegularItalic.woff2") format("woff2");
    font-style: italic;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Karbon";
    src: url("/fonts/Karbon-Light.woff") format("woff"),
        url("/fonts/Karbon-Light.woff2") format("woff2");
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Karbon";
    src: url("/fonts/Karbon-LightItalic.woff2") format("woff2");
    font-style: italic;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Karbon";
    src: url("/fonts/Karbon-Semibold.woff") format("woff"),
        url("/fonts/Karbon-Semibold.woff2") format("woff2");
    font-style: bold;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}
